
import { defineComponent } from 'vue';
import BookList from '@/components/admin/BookList.vue';

export default defineComponent({
  name: 'books',
  components: { BookList },

  methods: {
    newBook () {
      this.$router.push('/book/new/');
    }
  }
});
