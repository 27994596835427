
import { defineComponent } from 'vue';
import { Book, errorPrepend } from '@/models';
import { languageMixin } from '@/mixins/languageMixin';
import NoContent from '@/components/common/NoContent.vue';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import SortCaret from '@/components/common/SortCaret.vue';
import sortMixin from '@/mixins/sortMixin';

export default defineComponent({
  name: 'BookList',
  components: { SortCaret, NoContent },
  mixins: [languageMixin, sortMixin],
  data () {
    return {
      books: [] as Book[]
    };
  },
  async mounted () {
    try {
      await this.getBooks();
    } catch (e) {
      await showError(this.$t("messages.no books load") + errorPrepend());
    }
  },
  methods: {
    navigateToBook (book: Book) {
      this.$router.push(`/book/${book.id}`);
    },
    async deleteBook (book: Book) {
      const result = await confirmDelete(this.$t("messages.Delete?"), `${this.$t("messages.Delete book")} (${book.name})`);
      if (result.isConfirmed) {
        try {
          await Book.delete(book);
        } catch (e) {
          await showError(this.$t("messages.no book del") + errorPrepend());
          return;
        }
        await this.getBooks();
      }
    },
    async getBooks () {
      this.books = await Book.getAll();
    }
  },
  computed: {
    sortedBooks () {
      this.sortItems<Book>(this.books);
      return this.books;
    }
  }
});
